<template>
  <v-row class="px-5">
     <v-form ref="formRegistro" v-model="valid" lazy-validation>
      <Form :modelo="model" :fields="fieldRegistro" :edit="edit" :create="false" />
    </v-form>
    <v-btn color="error" @click="cancelar" :disabled="consulta" v-if="edit">cancelar</v-btn>
    <v-btn
      color="primary"
      :loading="consulta"
      @click="modificar(model)"
      v-if="edit"
      :disabled="!valid"
    >Modificar</v-btn>
  </v-row>
</template>
<script>
import Form from "@/common/util/crud/Form";
export default {
  components: { Form },
  data() {
    return {
      valid: true,
      consulta: false,
      edit: false,
      fieldRegistro: [],
      titulo: "Información del Registro"
    };
  },
  mounted() {
    this.fieldRegistro = [
      {
        label: "Fecha Recepción",
        model: "fechaCreacion",
        disabled: true,
        type: "date",
        birthday: false,
        class: "lg2 xs6"
      },
      {
        label: "Fecha Registro",
        model: "fechaValidacion",
        disabled: true,
        type: "date",
        birthday: false,
        class: "lg2 xs6"
      },
      {
        label: "Lugar Registro",
        model: "lugarRegistro",
        type: "text",

        class: "lg2",
        rules: "requiredSelect",
        classField: "required"
      },
      {
        label: "Código Número RPA",
        model: "codigoNumeroRegistroRpa",
        type: "text",
        disabled: true,
        class: "lg2 xs6"
      },
      {
        label: "Matrícula",
        model: "matricula",
        type: "text",
        rules: "required",
        classField: "required",
        class: "lg3 xs6"
      }
    ];
  },
  computed: {
    model() {
      return this.$store.state.abogado.registro;
    }
  },
  methods: {
    modificar(data) {
      if (this.$refs.formRegistro.validate()) {
        this.consulta = true;
        this.$http
          .put(`${this.urlBase}/${data.id}`, {
            lugarRegistro: data.lugarRegistro,
            matricula: data.matricula
          })
          .then(response => {
            this.consulta = false;
            this.edit = false;
          })
          .catch(error => {
            console.log(error);
            this.consulta = false;
          });
      }
    },
    cancelar() {
      this.edit = false;
    }
  }
};
</script>

